@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::after,
*::before {
  box-sizing: border-box;
}

@font-face {
  font-family: FontSans;
  src: url(../fonts/FontSans-Regular.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

:root {
  font-size: 17px;
  --color-text: #f7f4f0;
  --color-bg: #2b2929;
  --color-link: #f7f4f0;
  --color-link-hover: #fdff7f;
  --color-yellow: #fdff7f;
  --color-light-green: #3ad3b7;
  --color-dark-green: #18d17d;
  --color-light-blue: #00a9d1;
  --color-dark-blue: #3532c8;
  --page-padding: 1.5rem 2rem;
}

html {
  background-color: var(--color-bg);
}

body {
  margin: 0;
  color: var(--color-text);
  background-color: var(--color-bg);
  font-family: FontSans, area-normal, -apple-system, Helvetica, Arial,
    sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;
}

.transition-container {
  opacity: 1;
  transition: opacity 0.8s ease;
}

.transition-container {
  opacity: 0;
  transition: opacity 1s ease;
}

.fade-in {
  opacity: 1;
}
.fade-out {
  opacity: 0;
}

/* font-size */

html {
  font-size: 16px;
}
@media (min-width: 240px) {
  html {
    font-size: calc(8px + 8 * ((100vw - 240px) / 239));
  }
}
@media (min-width: 768px) {
  html {
    font-size: calc(12px + 4 * ((100vw - 768px) / 223));
  }
}
@media (min-width: 992px) {
  html {
    font-size: calc(10.67px + 5.33 * ((100vw - 992px) / 448));
  }
}

@media (min-width: 1440px) {
  html {
    font-size: calc(16px + 4 * ((100vw - 1440px) / 480));
  }
}

@media (min-width: 1920px) {
  html {
    font-size: 20px;
  }
}

.container {
  max-width: 96rem;
}

/* lenis */

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
  content: "";
  position: fixed;
  z-index: 1000;
}

.js .loading::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.js .loading::after {
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  opacity: 0;
  transform-origin: center;
  transform: rotate(45deg);
  background: var(--color-yellow);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  animation: loaderAnim 2s linear alternate forwards;
}

@keyframes loaderAnim {
  40% {
    opacity: 0;
    transform: translateY(40px) rotate(45deg);
  }
  60% {
    opacity: 1;
    transform: translateY(0) rotate(45deg);
  }
  80% {
    opacity: 1;
    transform: translateY(0) rotate(45deg);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px) rotate(45deg);
  }
}

/* links */

a {
  text-decoration: none;
  color: var(--color-link);
  outline: none;
  cursor: pointer;
}

a:hover {
  color: var(--color-link-hover);
  outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
  /* Provide a fallback style for browsers
	 that don't support :focus-visible */
  outline: none;
  background: lightgrey;
}

a:focus:not(:focus-visible) {
  /* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
  background: transparent;
}

a:focus-visible {
  /* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
  outline: 2px solid red;
  background: transparent;
}

.unbutton {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

.unbutton:focus {
  outline: none;
}

main {
  counter-reset: section;
}

.splitting .word {
  white-space: nowrap;
}

.vh-full-desktop {
  height: auto;
}

@media screen and (min-width: 1024px) {
  .vh-full-desktop {
    height: 100vh;
  }
}

.h-px {
  height: 1px;
}

.w-100vw {
  width: 100vw;
}

.w-150vw {
  width: 200vw;
}

@media screen and (min-width: 769px) {
  .w-150vw {
    width: 150vw;
  }
}

/* MENU */

.menu-batiment {
  background: #000;
  z-index: 999;
}

.menu-batiment a {
  font-size: calc(100vh / 15);
  line-height: 1;
  text-transform: uppercase;
}

.menu-link {
  align-self: start;
  justify-self: end;
  line-height: 1;
  grid-area: menu;
  overflow: hidden;
  position: relative;
  padding: 0 0 5px;
  font-size: var(--font-size-medium);
}

.menu--open ~ .menu-link,
.state-details .menu-link {
  pointer-events: none;
}

.menu-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: currentColor;
  transition: transform 0.4s ease;
  transform-origin: 100% 50%;
}

.menu-link:hover::after,
.menu--open ~ .menu-link::after,
.state-details .menu-link::after {
  transform: scale3d(0, 1, 1);
  transform-origin: 0% 50%;
}

.menu {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: auto auto auto;
  align-content: center;
  justify-content: center;
  counter-reset: menucounter;
}

.menu--open {
  pointer-events: auto;
  opacity: 1;
}

.state-details .menu {
  pointer-events: none;
}

.menu__item {
  grid-column: 2;
  line-height: 1;
  display: block;
  position: relative;
}

.menu__item-link {
  text-decoration: none;
  line-height: 1.25;
  margin-top: calc(-0.15 * var(--font-size-xlarge));
  font-size: var(--font-size-xlarge);
  cursor: pointer;
  position: relative;
  padding: 0 calc(0.45 * var(--font-size-xlarge));
  color: var(--color-menu);
}

.menu__item-link:hover {
  color: var(--color-menu-hover);
}

.menu__item-link::before {
  position: absolute;
  left: 0;
  top: 18%;
  text-align: right;
  counter-increment: menucounter;
  content: counters(menucounter, ".", decimal-leading-zero);
  opacity: 0;
  line-height: 1;
  padding-right: 0.5rem;
  display: grid;
  place-items: center;
  font-size: var(--font-size-small);
  color: var(--color-text);
  transform: scale3d(0, 0, 0);
}

.menu__item-link:hover::before {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  font-size: 3rem;
  line-height: 1;
  opacity: 0;
  pointer-events: none;
}

.close:hover {
  color: var(--color-menu);
}

.menu--open .close {
  opacity: 1;
  pointer-events: auto;
}

.state-details .close {
  pointer-events: none;
}

.ratio-45 {
  aspect-ratio: 4 / 5;
}

.video {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.navbar-bg {
  z-index: 999;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(41, 32, 32, 0.66) 50%,
    rgba(41, 32, 32, 1) 100%
  );
  opacity: 1;
  transition: opacity 0.5s ease;
}

.mobile-menu {
  background: rgba(41, 32, 32, 1);
  min-height: 100vh;
}

.fade-out-on-scroll {
  opacity: 0;
}

.card-bat .shape {
  opacity: 0;
  margin-top: 2rem;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.card-bat:hover .shape {
  opacity: 1;
  margin-top: 0;
}

.progress-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: var(--color-yellow);
  display: none; /* Add this line to hide the progress bar */
}

.bg-bat {
  background-size: cover;
}

.bg-bat-01 {
  background-image: url(../img/bat-featured/01_bat-featured_barrage-el-salto.jpg);
}

.bg-bat-02 {
  background-image: url(../img/bat-featured/02_bat-featured_ecole-chariatique-sarajevo.jpg);
}

.bg-bat-03 {
  background-image: url(../img/bat-featured/03_bat-featured_gare-oran.jpg);
}

.bg-bat-04 {
  background-image: url(../img/bat-featured/04_bat-featured_gare-tolede.jpg);
}

.bg-bat-05 {
  background-image: url(../img/bat-featured/05_bat-featured_gare-tozeur.jpg);
}

.bg-bat-06 {
  background-image: url(../img/bat-featured/06_bat-featured_hopital-avicene.jpg);
}

.bg-bat-07 {
  background-image: url(../img/bat-featured/07_bat-featured_mache-tunis.jpg);
}

.bg-bat-08 {
  background-image: url(../img/bat-featured/08_bat-featured_mosquee-bejaia.jpg);
}

.bg-bat-09 {
  background-image: url(../img/bat-featured/09_bat-featured_palais-bey.jpg);
}

.bg-bat-10 {
  background-image: url(../img/bat-featured/10_bat-featured_synagogue-sarajevo.jpg);
}

.bg-bat-11 {
  background-image: url(../img/bat-featured/11_bat-featured_synagogue-chalons.jpg);
}

.bg-bat-12 {
  background-image: url(../img/bat-featured/12_bat-featured_vijecnica-sarajevo.jpg);
}

.pays-es a:hover {
  color: var(--color-light-green);
}

.pays-fr a:hover {
  color: var(--color-dark-green);
}

.pays-tu a:hover {
  color: var(--color-light-blue);
}

.pays-bo a:hover {
  color: var(--color-dark-blue);
}

.pays-al a:hover {
  color: var(--color-yellow);
}

.scroll-ico {
  width: 8px;
  height: 8px;
  background-color: var(--color-text);
  border-radius: 50%;
  opacity: 1;
  transform: translateY(0px);
  animation: wheel 2s cubic-bezier(0.64, 0, 0.78, 0) infinite;
  -webkit-animation: wheel 2s cubic-bezier(0.64, 0, 0.78, 0) infinite;
}

@keyframes wheel {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  10% {
    opacity: 1;
    transform: translateY(0);
  }

  25% {
    opacity: 1;
    transform: translateY(0);
  }
  75% {
    opacity: 0;
    transform: translateY(16px);
  }
  100% {
    opacity: 0;
    transform: translateY(16px);
  }
}

@-webkit-keyframes wheel {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  10% {
    opacity: 1;
    transform: translateY(0);
  }

  25% {
    opacity: 1;
    transform: translateY(0);
  }
  75% {
    opacity: 0;
    transform: translateY(16px);
  }
  100% {
    opacity: 0;
    transform: translateY(16px);
  }
}

.bat-list .arrow {
  opacity: 0;
  transform: translateX(-16px);
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.bat-list:hover .arrow {
  opacity: 1;
  transform: translateX(0px);
}

.text-dark {
  color: var(--color-bg);
}

.bg-white {
  background-color: var(--color-text);
}

.bg-dark-green {
  background-color: var(--color-dark-green);
}

.bg-light-green {
  background-color: #3ad3b7;
}

.bg-yellow {
  background-color: #fdff7f;
}

.bg-light-blue {
  background-color: #00a9d1;
}

.bg-dark-blue {
  background-color: #3532c8;
}

/* STYLING BUTTON */

.marquee-btn {
  width: 200px;
  height: 32px;
  padding: 5px 0;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  color: var(--color-bg);
  background: var(--color-link);
  border-radius: 999px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .marquee-btn {
    width: 280px;
    height: 44px;
  }
}

.marquee-btn:hover {
  background-color: var(--color-yellow);
}

.marquee-btn span {
  animation: marquee 15s infinite normal linear;
  padding: 0 0.5em;
}

@keyframes marquee {
  to {
    transform: translateX(-100%);
  }
}

/*MAP*/

#map {
  height: 0;
  padding-bottom: 65%;
  display: block;
  position: relative;

  /* set map background as needed */
  background-color: var(--color-bg);
  background-image: url(../img/map-2-imaneo.svg);
  background-position: center;
  background-size: cover; /* any size that stretches the background */
  background-repeat: no-repeat;
}

#map li {
  /* pin's label */
  display: block;
  position: absolute;
  width: 2rem;
  height: 2rem;
  cursor: pointer;

  /* total dimensions including arrow:
      
  height is 2.2rem
  width is 2rem
 
  we want to translate the "anchor point" to the pin's tip': */
  transform: translateX(-1rem) /* go backwards 1/2x total width */
    translateY(-2.2rem); /* go backwards 1x height */

  transition: transform 0.5s ease-out, background-color 0.25s ease-out;
}

#map li:hover {
  transform: translateX(-1rem) /* keep same settings */ translateY(-2.5rem); /* slightly higher setting */
}

#map a.navlink .fixed span {
  opacity: 0%;
  transform: translateY(1rem);
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

#map a.navlink:hover .fixed span:nth-child(1) {
  transition-delay: 0.1;
  opacity: 100%;
  transform: translateY(0rem);
}

#map a.navlink:hover .fixed span:nth-child(2) {
  transition-delay: 0.9;
  opacity: 100%;
  transform: translateY(0rem);
}

/* #map a.navlink:hover .fixed span {
  opacity: 100%;
  transform: translateY(0rem);
} */
